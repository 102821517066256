










































import { Component, Prop, Vue } from 'vue-property-decorator';
import ShowContent from '@shared/components/ShowContent.vue';

@Component({
  components: {
		ShowContent
  }
})
export default class AfterPartnerRegistration extends Vue {

}
