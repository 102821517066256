
























































































































































































































































































































































import { required, minLength, sameAs, email, numeric, maxLength } from 'vuelidate/lib/validators';
import { passwordRegex, phoneFormat, phoneValidation } from '@shared/services/validators';
import AfterPartnerRegistration from '@rs-911/components/AfterPartnerRegistration.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
Component.registerHooks(['validations']);

@Component({
  components: {
    AfterPartnerRegistration
  }
})
export default class RegisterSecondPartner extends Vue {
  $v: any;
  loginFailMessage = '';
  showOverlay = false;
  registrationSuccess = false;
  options = [
    { value: '', text: '' },
    { value: 'Mr.', text: 'Mr.' },
    { value: 'Mrs.', text: 'Mrs.' },
    { value: 'Ms.', text: 'Ms.' },
  ]
  form = {
    email: null,
    password: null,
    matchPass: null,
    Name: null,
    Address1: null,
    Address2: null,
    City: null,
    State: null,
    Zip: null,
    ContactFirstName: null,
    ContactLastName: null,
    ContactPrefix: null,
    ContactTitle: null,
    ContactEmail: null,
    ContactPhone: '',
    ContactPhoneExt: null
  }

  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        Name: {
          required
        },
        password: {
          required,
          passwordRegex,
          minLength: minLength(6),
        },
        matchPass: {
          required,
          matchPasswords: sameAs('password')
        },
        Address1: {
          required
        },
        Address2: {},
        City: {
          required
        },
        State: {
          required
        },
        Zip: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        ContactFirstName: {
          required
        },
        ContactLastName: {
          required
        },
        ContactPrefix: {},
        ContactTitle: {
          required
        },
        ContactEmail: {
          required,
          email
        },
        ContactPhone: {
          required,
          phoneValidation
        },
        ContactPhoneExt: {}
      }
    };
  }

  @Watch('form.ContactPhone')
  formContactPhone(newVal: string) {
    this.form.ContactPhone = phoneFormat(newVal);
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      email: null,
      password: null,
      matchPass: null,
      Name: null,
      Address1: null,
      Address2: null,
      City: null,
      State: null,
      Zip: null,
      ContactFirstName: null,
      ContactLastName: null,
      ContactPrefix: null,
      ContactTitle: null,
      ContactEmail: null,
      ContactPhone: '',
      ContactPhoneExt: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    this.registrationSuccess = false;
    if (this.$v.form.$anyError) {
      return;
    }
    this.showOverlay = true;
    const sendForm = {...this.form};
    sendForm.ContactPhone = sendForm.ContactPhone.replace(/\D/g, '');

    this.loginFailMessage = '';

    this.$store.dispatch('auth/registerSecondPartner', sendForm)
      .then(() => {
        this.registrationSuccess = true;
        this.resetForm();
        this.showOverlay = false;
      })
      .catch(err => {
        this.loginFailMessage = err.data.message;
        this.showOverlay = false;
      });
  }
  
}
